<template>
	<client-page class="wrap wrap--mypage">
        <!-- 서브비주얼 -->
        <sub-visual v-if="scope.includes('seller')" :visual="$t('common.셀러 회원 마이페이지')"></sub-visual>
        <sub-visual v-if="scope.includes('buyer')" :visual="$t('common.바이어 회원 마이페이지')"></sub-visual>

		<div class="contents">
			<div class="ct">
				<div class="mypage-container">
					<!-- 네비게이션 메뉴 -->
					<mypage-navigation></mypage-navigation>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
							<!-- <h2 class="mypage-contents__title"></h2> -->
						</div>
						<div class="mypage-contents__body">
                            <h3 class="mypage-title--sm mb-2">상품 정보 수정하기</h3>
                            <div class="text-explain mb-5">
                                <p class="color-primary">상품 정보를 수정하신 뒤 하단의 <strong>저장</strong> 버튼을 꼭 눌러주세요.</p>
                                <small>※ 업로드하는 상품소개서 등에는 개인정보가 보여질 수 있으니 꼭 확인 부탁드립니다.</small>
                            </div>
                            <product-input></product-input>
						</div>
					</div>
				</div>
			</div>
		</div>
	</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ProductInput from "@/components/client/fair/product-input.vue";

export default{
	components: {
		ClientPage,
		SubVisual,
		MypageNavigation,
        ProductInput,
	},
	data() {
		return {
		};
	},
	computed: {
		payload(){
			return this.$store.state.payload || {};
		},
		scope(){
			return this.payload.scope || [];
		}
	}
}
</script>
