<template>
	<client-page class="wrap wrap--mypage">
        <!-- 서브비주얼 -->
        <sub-visual v-if="scope.includes('seller')" :visual="$t('common.셀러 회원 마이페이지')"></sub-visual>
        <sub-visual v-if="scope.includes('buyer')" :visual="$t('common.바이어 회원 마이페이지')"></sub-visual>

		<div class="contents">
			<div class="ct">
				<div class="mypage-container">
					<!-- 네비게이션 메뉴 -->
					<mypage-navigation></mypage-navigation>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
							<!-- <h2 class="mypage-contents__title"></h2> -->
						</div>
						<div class="mypage-contents__body">
                            <div class="text-ecplain mb-3">
                                <p>상품 정보 수정이 필요하신 경우 <strong>각 상품 내</strong>에서 직접 수정을 부탁드립니다.</p>
                            </div>
                            <product-list v-slot="{ products }" type="me" show-pagination>
                                <product-list-item v-for="product in products" :key="product._id" :product="product" :to="`/mypage/products/${product._id}`"></product-list-item>
                            </product-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ProductList from "@/components/client/fair/product-list.vue";
import ProductListItem from "@/components/client/fair/product-list-item.vue";

export default{
	components: {
		ClientPage,
		SubVisual,
		MypageNavigation,
        ProductList,
        ProductListItem
	},
	data() {
		return {
		};
	},
	computed: {
		payload(){
			return this.$store.state.payload || {};
		},
		scope(){
			return this.payload.scope || [];
		}
	}
}
</script>
