var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap wrap--mypage"
  }, [_vm.scope.includes('seller') ? _c('sub-visual', {
    attrs: {
      "visual": _vm.$t('common.셀러 회원 마이페이지')
    }
  }) : _vm._e(), _vm.scope.includes('buyer') ? _c('sub-visual', {
    attrs: {
      "visual": _vm.$t('common.바이어 회원 마이페이지')
    }
  }) : _vm._e(), _c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "ct"
  }, [_c('div', {
    staticClass: "mypage-container"
  }, [_c('mypage-navigation'), _c('div', {
    staticClass: "mypage-contents"
  }, [_c('div', {
    staticClass: "mypage-contents__head"
  }), _c('div', {
    staticClass: "mypage-contents__body"
  }, [_c('h3', {
    staticClass: "mypage-title--sm mb-2"
  }, [_vm._v("상품 정보 수정하기")]), _c('div', {
    staticClass: "text-explain mb-5"
  }, [_c('p', {
    staticClass: "color-primary"
  }, [_vm._v("상품 정보를 수정하신 뒤 하단의 "), _c('strong', [_vm._v("저장")]), _vm._v(" 버튼을 꼭 눌러주세요.")]), _c('small', [_vm._v("※ 업로드하는 상품소개서 등에는 개인정보가 보여질 수 있으니 꼭 확인 부탁드립니다.")])]), _c('product-input')], 1)])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }