<template>
    <v-responsive v-if="product" max-width="1024px" width="100%" height="100%" class="shop-product-view pa-4 grey lighten-3">
        <v-card>
            <v-card-title class="subtitle-1">카테고리</v-card-title>
            <v-card-text>
                <category-select v-model="product._category" :categories="categories" @input="input" :allow-depth="[2]"></category-select>
            </v-card-text>
        </v-card>

        <v-row class="mt-4">
            <v-col cols="12" md="6" sm="6" class="pr-md-2">
                <v-card>
                    <v-card-title class="subtitle-1 font-weight-bold">상품 썸네일</v-card-title>
                    <v-card-text>
                        <product-image v-model="product.thumb" label="썸네일" @remove="removeThumb" @input="input"></product-image>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-1 font-weight-bold">상품 이미지</v-card-title>
                    <v-card-text>
                        <!-- <draggable v-model="product.images"> -->
                            <!-- <transition-group> -->
                                <product-image v-for="(i, index) in 5" v-model="product.images[index]" :label="`이미지 ${i}`" :key="index" @remove="removeImage" @input="input"></product-image>
                            <!-- </transition-group> -->
                        <!-- </draggable> -->
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="pl-md-2">
                <v-card>
                    <v-card-title>
                        <v-row align="start">
                            <v-col cols="auto" class="subtitle-2 font-weight-bold">
                                상품 정보
                            </v-col>
                            <v-spacer />
                            <v-col>
                                <v-select v-model="locale" :items="locales" item-text="text" item-value="value" label="언어" placeholder=" " dense hide-details class="mt-0"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="product.name[locale]" label="상품명" placeholder=" " @input="input" disabled></v-text-field>
                        <v-textarea v-model="product.summary[locale]" label="주요특징" placeholder=" " @input="input"></v-textarea>
                        <v-text-field v-model="product.country[locale]" label="제조국" placeholder=" " @input="input"></v-text-field>
                        <v-text-field v-model="product.size[locale]" label="사이즈" placeholder=" " @input="input"></v-text-field>
                        <v-text-field v-model="product.color[locale]" label="칼라" placeholder=" " @input="input"></v-text-field>
                        <v-text-field v-model="product.elements[locale]" label="전성분" placeholder=" " @input="input"></v-text-field>
                        <v-text-field v-model="product.onlineDistributor[locale]" label="주요판로처(온라인)" placeholder=" " @input="input"></v-text-field>
                        <v-text-field v-model="product.offlineDistributor[locale]" label="주요판로처(오프라인)" placeholder=" " @input="input"></v-text-field>

                        <v-text-field v-model="product.manufacture" label="제조방법" placeholder=" " @input="input"></v-text-field>
                        <v-text-field v-model="product.company" label="제조업체" placeholder=" " @input="input"></v-text-field>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-text>
                        <v-text-field v-model="product.price" label="소비자가(KRW)" placeholder=" " @input="input"></v-text-field>
                        <v-text-field v-model="product.salePrice" label="판매가(KRW)" placeholder=" " @input="input"></v-text-field>
                        <v-text-field v-model="product.salesAmount" label="매출액(KRW)" placeholder=" " @input="input"></v-text-field>
                        <v-text-field v-model="product.exportsAmount" label="수출액($)" placeholder=" " @input="input"></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6" class="pr-md-2">
                <v-card>
                    <v-card-title class="subtitle-2 font-weight-bold">
                        인증정보
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="product.awardKind" label="인증구분" placeholder=" " hide-details class="mt-3" disabled></v-text-field>
                        <v-text-field v-model="product.awardNumber" label="인증번호" placeholder=" " hide-details class="mt-3 mb-6" disabled></v-text-field>
                        <v-date-field v-model="product.awardAt" label="선정일" placeholder=" " hide-details disabled></v-date-field>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-2">
                <v-card>
                    <v-card-title class="subtitle-2 font-weight-bold">
                        URL 링크
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="product.site" label="판매 사이트" placeholder=" " hide-details class="mt-3"></v-text-field>
                        <v-text-field v-model="product.homepage" label="홈페이지" placeholder=" " hide-details class="mt-3"></v-text-field>
                        <v-text-field v-model="product.promotion" label="홍보마당 URL" placeholder=" " hide-details class="mt-3" disabled></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row align="top" class="mt-3">
            <v-col
                cols="12"
            >
                <div class="text-explain">
                    <p>상기 기재된 정보 외에 해외 수출 인증, 상세페이지 등 상품 정보를 자유롭게 기재해 주십시오.</p>
                    <p><small>ex1) 수출 허가 관련 - 중국 위생허가(), 미국 FDA, 유럽 CPNP 등 인증 사항</small></p>
                    <p><small>ex2) 제품 인증 관련 - 특허, KAS, KS 등 인증 사항</small></p>
                    <p><small>ex3) 제품 상세페이지 업로드 등</small></p>
                    <p class="color-primary mt-2">※ 추가 등록 정보는 국내외 바이어가 확인하는 내용으로 번역되어 제공되지 않으니 국문 외의 언어로도 입력 바랍니다.</p>
                </div>
            </v-col>
            <v-col 
                cols="auto"
                class="ml-auto"
            >
                <v-select v-model="locale" :items="locales" item-text="text" item-value="value" label="언어" placeholder=" " dense hide-details class="mt-0"></v-select>
            </v-col>
        </v-row>
        <div class="white elevation-1">
            <div class="editor" :class="{ 'hidden-editor': locale != 'ko' }">
                <naver-smarteditor v-model="product.content.ko"></naver-smarteditor>
            </div>
            <div class="editor" :class="{ 'hidden-editor': locale != 'en' }">
                <naver-smarteditor v-model="product.content.en"></naver-smarteditor>
            </div>
            <div class="editor" :class="{ 'hidden-editor': locale != 'cn' }">
                <naver-smarteditor v-model="product.content.cn"></naver-smarteditor>
            </div>
        </div>

        <v-row justify="center" class="mt-4">
            <v-col cols="auto">
                <v-btn color="primary" rounded @click="save">저장하기</v-btn>
            </v-col>
        </v-row>
    </v-responsive>
</template>

<script>
import api from "@/api";

import draggable from 'vuedraggable'
import NaverSmarteditor from '@/components/plugins/naver/naver-smarteditor.vue';

import CategorySelect from '@/components/console/shop/categories/category-select.vue';
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

import ProductImage from "@/components/console/shop/products/product-image.vue";

export default {
    components: {
        draggable,
        NaverSmarteditor,
        ProductImage,
        CategorySelect,
        VDateField,
    },
    data(){
        return {
            ready: null,

            product: null,

            hashtag: null,
            brands: [],
            categories: [],

            locale: this.$i18n.locale,
            locales: [
                { text: "한국어", value: "ko" },
                { text: "영어", value: "en" },
                { text: "중국어", value: "cn" },
            ]
        }
    },
    mounted(){
        this.init();
    },

    methods: {
        async init(){
            let { categories } = await api.v1.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;

            let { product } = await api.v1.me.products.get({ _id: this.$route.params._product });
            
            try{
                if(product.thumb) product.thumb = await api.getResource(product.thumb);
                for(let index = 0; index < 5; index++ ){
                    product.images[index] = product.images[index] ? await api.getResource(product.images[index]) : product.images[index];
                }
            }
            catch(error){
                console.error(error);
            }

            this.product = {
                ...this.product,
                ...product
            }
        },

        async save(){
            var { product } = await api.v1.me.products.put(this.product);

            if(this.product.thumb) await api.v1.me.products.thumb.post(product._id, this.product.thumb);

            for(var index in this.product.images){
                if(this.product.images[index]) await api.v1.me.products.images.post(product._id, index, this.product.images[index]);
            }

            alert("저장되었습니다");

            this.$router.go(-1);
        },

        input(){
            this.$emit("input", this.product);
        },

        async removeThumb(){
            if(confirm("상품 썸네일을 삭제하시겠습니까?")){
                if(this.product._id) await api.console.shop.products.thumb.delete(this.product._id);
                this.product.thumb = null;
                this.$emit("input", this.product);
            }
        },

        async removeImage(index){
            if(confirm("상품 이미지를 삭제하시겠습니까?")){
                if(this.product._id) await api.console.shop.products.images.delete(this.product._id, index);
                this.product.images[index] = undefined;
                this.$forceUpdate();
            }
        },
    },
    computed: {
        optionsStock(){
            var stock = 0;
            for(var i in this.product.options){
                stock += this.product.options[i].stock;
            }
            return stock;
        }
    }
}
</script>

<style>
.shop-product-view .weight input { text-align: end; }
.shop-product-view .amount input { text-align: end; }
.shop-product-view .theme--light.v-tabs-items { background-color: transparent !important; }
.shop-product-view .option-groups-headers th { border-bottom: thin solid rgba(0,0,0,.12) }
.editor div { min-height: auto !important; }
.hidden-editor { width: 0; height: 0; overflow: hidden; }
</style>
